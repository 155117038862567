export default [
  {
    label: 'ID',
    field: 'userId',
  },
  {
    label: 'Position',
    field: 'position',
  },
  {
    label: 'Telegram ID',
    field: 'telegramId',
  },
  {
    label: ' Telegram Username',
    field: 'telegramName',
  },
  {
    label: 'User Name',
    field: 'userName',
  },
  {
    label: 'Referrals',
    field: 'referrals',
  },
  {
    label: 'Balance',
    field: 'balance',
  },
]
