<template>
  <AppTable
    :rows="holdUsers"
    :columns="columns"
    :is-loading="isRequestInProgress"
  >
    <template #prependFilters>
      <b-row class="w-100">
        <b-col
          md="4"
          xl="4"
          class="mb-1"        >
          <b-form-group
            label="Type Period"
            label-for="type-period"
          >
            <v-select
              id="type-period"
              v-model="currentPeriodType"
              :options="periodTypeOptions"
              label="label"
              placeholder="Select period type"
              :reduce="option => option.value"
              @input="fetchPeriodsData"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Type Rating"
            label-for="type-rating"
          >
            <v-select
              id="type-rating"
              v-model="currentRatingType"
              :options="ratingOptions"
              label="label"
              placeholder="Select rating type"
              :reduce="option => option.value"
              @input="fetchPeriodsData"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Period Start"
            label-for="period"
          >
            <v-select
              id="period"
              v-model="period"
              :options="periods"
              label="label"
              placeholder="Select start period"
              :reduce="option => option.value"
              @input="fetchHoldLeaderboardData"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template #default="{ column, row, formattedRow } = {}">
      <TableRow
        :row="row"
        :column="column"
        :formatted-row="formattedRow"
      />
      <span v-if="column.field === 'telegramName'">
        <a
          v-if="row.telegramName"
          :href="`https://t.me/${row.telegramName}`"
          target="_blank"
        >
          @{{ row.telegramName }}
        </a>
        <span v-else>
          -
        </span>
      </span>
      <span v-else-if="column.field === 'userId'">
        {{ row.userId }}
      </span>
      <span v-else-if="column.field === 'telegramId'">
        {{ row.telegramId }}
      </span>
      <span v-else-if="column.field === 'position'">
        {{ row.position }}
      </span>
      <span v-else-if="column.field === 'userName'">
        <b-button
          variant="link"
          target="_blank"
          @click="goToUser(row.userId)"
        >
          {{ row.userName || row.userId }}
        </b-button>
      </span>
      <span v-else-if="column.field === 'balance'">
        {{ row.balance }}
      </span>
      <span v-else-if="column.field === 'referrals'">
        {{ row.referrals }}
      </span>
    </template>
  </AppTable>
</template>

<script>
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  BCol, BFormGroup, BRow, BButton,
} from 'bootstrap-vue'
import { useRouter } from '@core/utils/utils'
import AppTable from '@/components/AppTable.vue'
import TableRow from '@/components/TableRow.vue'
import columns from './config/tableConfig'
import periodTypeOptions from './config/periodTypeOptions'
import ratingOptions from './config/ratingOptions'
import { fetchHoldLeaderboard, fetchPeriods } from './api'

export default {
  name: 'HoldLeaderboard',
  components: {
    AppTable,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    TableRow,
    BButton,
  },
  setup() {
    const holdUsers = ref([])
    const periods = ref([])
    const isWinnersRequestInProgress = ref(false)
    const isPeriodRequestInProgress = ref(false)
    const period = ref(null)
    const currentPeriodType = ref('week')
    const currentRatingType = ref(null)

    const isRequestInProgress = computed(() => isWinnersRequestInProgress.value || isPeriodRequestInProgress.value)

    const fetchHoldLeaderboardData = async () => {
      isWinnersRequestInProgress.value = true
      try {
        if (!period.value || periods.value.length === 0) {
          holdUsers.value = []
          return
        }
        holdUsers.value = await fetchHoldLeaderboard(period.value, currentRatingType.value, currentPeriodType.value)
      } catch (error) {
        console.error('Error in fetchHoldLeaderboardData:', error)
      } finally {
        isWinnersRequestInProgress.value = false
      }
    }

    const fetchPeriodsData = async () => {
      isPeriodRequestInProgress.value = true
      try {
        periods.value = await fetchPeriods(currentPeriodType.value, currentRatingType.value)
        period.value = periods.value[periods.value.length - 1]?.value
      } catch (error) {
        console.error('Error in fetchPeriodsData:', error)
      } finally {
        isPeriodRequestInProgress.value = false
      }
    }

    const init = async () => {
      await fetchPeriodsData()
      await fetchHoldLeaderboardData()
    }

    onMounted(init)

    watch([currentPeriodType, currentRatingType], () => {
      init()
    })

    const { router } = useRouter()

    const goToUser = userId => {
      router.push(`/user/${userId}`)
    }

    return {
      columns,
      holdUsers,
      periods,
      period,
      periodTypeOptions,
      ratingOptions,
      currentPeriodType,
      currentRatingType,
      isRequestInProgress,
      fetchHoldLeaderboardData,
      fetchPeriodsData,
      goToUser,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
