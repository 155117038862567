export default [
  {
    value: 'personal',
    label: 'Personal Rating',
  },
  {
    value: 'referral',
    label: 'Referral Rating',
  },
  {
    value: null,
    label: 'Total Rating',
  },
]
