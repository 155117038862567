import axios from '@/plugins/axios'

export const fetchHoldLeaderboard = async (periodStartedAt, leaderboardType, periodType = 'week') => {
  try {
    const response = await axios.get('/hold/leaderboard-winners', {
      params: {
        periodStartedAt,
        periodType: periodType ?? 'week',
        leaderboardType,
      },
    })
    return response.data.leaders
  } catch (error) {
    console.error('Error fetching hold leaderboard:', error)
    throw error
  }
}

export const fetchPeriods = async (periodType = 'week', leaderboardType = null) => {
  try {
    const response = await axios.get('/hold/get-winner-periods-starts', {
      params: {
        periodType: periodType ?? 'week',
        leaderboardType,
      },
    })
    return response.data.winnersPeriodStarts.map(p => ({
      label: new Date(p).toLocaleDateString(),
      value: p,
    }))
  } catch (error) {
    console.error('Error fetching hold leaderboard periods:', error)
    throw error
  }
}
