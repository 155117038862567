export default [
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: null,
    label: 'Week',
  },
]
